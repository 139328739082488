import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, tap, map, switchMap, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { INav, IPage, IPageObject, IContent,  ISliderObject, INavObject, IContentObject, IPagesObject } from './model/model.index';
import { IContentData } from './model/IContentDisplayType';

@Injectable({
  providedIn: 'root'
})
export default class SharedService {
  private readonly _cmsApiUrl: string;
  constructor(
    private http: HttpClient,
    private router: Router) {
    this._cmsApiUrl = environment.cmsApiBaseUrl;
  }

  getNavMenu(): Observable<INavObject> {
    return this.http.get<INavObject>(`${this._cmsApiUrl}/navs?filters[is_parent]=true&filters[is_disabled]=false&populate=*`)
      .pipe(catchError(err => {
        return this.handleError(err, this.router);
      }));
  }

  //https://api.rts.mskcc.org/api/pages?populate=*&filters[uid][$eq]=10
  //https://api.rts.mskcc.org/api/pages/10?populate=*
  //https://api.rts.mskcc.org/api/pages?uid=10
  //https://api.rts.mskcc.org/api/pages?filters[uid]=25
  getPageByUID(uid: number): Observable<IPageObject> {
    return this.http.get<IPageObject>(`${this._cmsApiUrl}/pages?populate=*&filters[uid][$eq]=${uid}`)
    //return this.http.get<IPageObject>(`${this._cmsApiUrl}/pages/${uid}?populate=*`)
      .pipe(
        catchError(err => {
          return this.handleError(err, this.router);
        }));
  }

  //NOT USED
  getPageContentByIDNotUsed(uid: number): Observable<IContentObject> {
    return this.http.get<IContentObject>(`${this._cmsApiUrl}/contents?page.uid=${uid}&is_active=true&_sort=order&populate=*`)
      .pipe(catchError(err => {
        return this.handleError(err, this.router);
      }));
  }

  //contents?populate=*&filters[page][uid][$eq]=${uid}
  getPageContentByID(uid: number, sort: string = null): Observable<IContentObject> {
   
    let url = sort != null ?
    `${this._cmsApiUrl}/contents?filters[page][uid][$eq]=${uid}&populate[subcontents][populate][0]=subcontents&is_active=true&populate[images]=*&sort[0]=${sort}` :
    `${this._cmsApiUrl}/contents?filters[page][uid][$eq]=${uid}&populate[subcontents][populate][0]=subcontents&is_active=true&populate[images]=*&sort[0]=order`;

    return this.http.get<IContentObject>(url)
      .pipe(
        map(contents => {

          contents.data.forEach(c => {
            if (c.attributes.subcontents.data.length >= 1) {
              c.attributes.subcontents.data = c.attributes.subcontents.data.sort((a, b) => (a.attributes.order > b.attributes.order) ? 1 : -1);
            }
            let subContentsLen = c.attributes.subcontents.data.length;

            for (var i = 0; i < subContentsLen; i++) {
              //let nestedSubContents = c.attributes.subcontents.data[i].attributes;
              //c.attributes.subcontents.data[i].attributes.subcontents.data = nestedSubContents.subcontents.data.sort((a, b) => (a.attributes.order > b.attributes.order) ? 1 : -1);
            }

          });

          return contents;

        }),
        catchError(err => {
          return this.handleError(err, this.router);
        }));
  }

  getSliders(): Observable<ISliderObject> {
    return this.http.get<ISliderObject>(`${this._cmsApiUrl}/sliders?is_active=true&_sort=order&populate=*`)
      .pipe(catchError(err => {
        return this.handleError(err, this.router);
      }));
  }

  getSearchPagesByDescription(search: string): Observable<IPagesObject> {
    return this.http.get<IPagesObject>(`${this._cmsApiUrl}/pages?filters[$or][0][title][$contains]=${search}&filters[$or][1][description][$contains]=${search}&filters[$or][2][notification][$contains]=${search}&filters[$and][3][is_active][$eq]=true&sort[0]=title&populate=*`)
      .pipe(
        map(pages => {

          return pages;
        }),
        catchError(err => {
          return this.handleError(err, this.router);
        }));
  }


  getSearchContent(search: string): Observable<IContentObject> {
    //let contentURL = `${this._cmsApiUrl}/contents?is_active=true&description_contains=${description}&_sort=order&populate=*`;
    //let subContentURL = `${this._cmsApiUrl}/contents?is_active=true&subcontents.description_contains=${description}&_sort=order&populate=*`;
    //let nestedSubContentURL = `${this._cmsApiUrl}/contents?is_active=true&subcontents.subcontents.description_contains=${description}&_sort=order&populate=*`;

    //let contentRequest = this.http.get<IContentObject[]>(contentURL);
    //let subContentRequest = this.http.get<IContentObject[]>(subContentURL);
    //let nestedSubContentRequest = this.http.get<IContentObject[]>(nestedSubContentURL);

    return this.http.get<IContentObject>(`${this._cmsApiUrl}/contents?filters[$or][0][title][$contains]=${search}&filters[$or][1][description][$contains]=${search}&filters[$or][2][notification][$contains]=${search}&filters[$or][3][subcontents][title][$contains]=${search}&filters[$and][4][is_active][$eq]=true&sort[0]=title&populate=*`)
      .pipe(
        map(contents => {

          contents.data.forEach(c => {
            if (c.attributes.subcontents && c.attributes.subcontents.data.length >= 1) {
              c.attributes.subcontents.data = c.attributes.subcontents.data.sort((a, b) => (a.attributes.order > b.attributes.order) ? 1 : -1);
            }
            //let subContentsLen = c.attributes.subcontents.data.length;

            //for (var i = 0; i < subContentsLen; i++) {
            //let nestedSubContents = c.attributes.subcontents.data[i].attributes;
            //c.attributes.subcontents.data[i].attributes.subcontents.data = nestedSubContents.subcontents.data.sort((a, b) => (a.attributes.order > b.attributes.order) ? 1 : -1);
            //}

          });

          return contents;
        }),
        catchError(err => {
          return this.handleError(err, this.router);
        }));

  }

  private handleError(err: HttpErrorResponse, router: Router) {
    let errorMessage = '';
    if (err.status == 404) {
      router.navigateByUrl("/notfound");
      //errorMessage = `TEST. UNCOMMENT ABOVE`;
    }

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured:${err.error.message}`;
    }
    else {
      errorMessage = `Server return code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);

    return throwError(errorMessage);
  }
}
