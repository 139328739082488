<header>
  <nav class="navbar is-link" role="navigation" aria-label="main navigation" >
    <div class="container">
      <div class="navbar-brand">
        <a role="button" class="navbar-burger burger" aria-label="menu" [attr.aria-expanded]="isExpanded"
           data-target="navbarBasicExample" (click)="toggle()">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" [ngClass]="{ 'is-active': isExpanded }" style="min-height:8rem">



        <div class="navbar-start">
          <a class="" href="/">
            <!--<img src="../../assets/images/RTS-NEW-LOGO.png" style="height: 125px; padding-top: 10px;  padding-right: 6rem; ">-->
            <img src="../../assets/images/rts-logo-one-line.png" style="height: 125px; padding-top: 10px;  padding-right: 6rem; ">
          </a>



        </div>
        <div class="navbar-end">

        </div>
        <ng-container *ngFor="let navMenuItem of navMenuData">
            <ng-container  *ngIf="navMenuItem.attributes.sub_navs && navMenuItem.attributes.sub_navs.data.length == 0  else nestedNav; ">
            <a class="navbar-item" [attr.href]="navMenuItem.attributes.url" target="_blank" *ngIf="navMenuItem.attributes.is_external">
              {{navMenuItem.attributes.name}}
            </a>
            <a class="navbar-item" [routerLink]="navMenuItem.attributes.url" *ngIf="!navMenuItem.attributes.is_external">
              {{navMenuItem.attributes.name}}
            </a>
          </ng-container>

           <ng-template #nestedNav>
            <div class="navbar-item is-hoverable has-dropdown">
              <a class="navbar-link">
                {{navMenuItem.attributes.name}}
              </a>
              <div class="navbar-dropdown" *ngIf="navMenuItem.attributes.sub_navs.data && navMenuItem.attributes.sub_navs.data.length > 0">
                <ng-container *ngFor="let sub_nav of navMenuItem.attributes.sub_navs.data">
                  <a class="navbar-item" [attr.href]="sub_nav.attributes.attributes.url" target="_blank" *ngIf="sub_nav.attributes.is_external && !sub_nav.attributes.is_disabled">
                    {{sub_nav.attributes.name}}
                  </a>
                  <a class="navbar-item" [routerLink]="sub_nav.attributes.url" *ngIf="!sub_nav.attributes.is_external && !sub_nav.attributes.is_disabled">
                    {{sub_nav.attributes.name}}
                  </a>
                </ng-container>
              </div>
            </div>
          </ng-template> 
        </ng-container>
      </div>

    </div>
  </nav>


</header>
