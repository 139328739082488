<div class="" style="background-color: yellow; height: 10rem; position: relative;">
  <h1 style="top:60px;position:absolute; left:212px; font-size:xx-large; font-weight: bolder; color:yellow; height:8rem">{{page.title}}</h1>
  <img src="../../../assets/images/rts-header.jpeg" style="height:100%; width:100%" />
</div>

<section class="content section">
  <div class="container" *ngIf="page">

    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>


    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
    </div>
  </div>




  <div class="m-b-sm m-t-sm columns m-2" *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">

    <div class="column is-6" style="margin-left:17%; width:33%">
      <div *ngFor="let content of contents" [id]="content.content_uid">
        <div class="box" *ngIf="content.order%2 ===0" style="margin-bottom:10px; height:22rem">
          <article class="media">
            <div class="media-left" *ngIf="content.images.length">
              <figure class="image is-128x128">
                <!--img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
                <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <strong style="font-size:24px" [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                <span class="ml-1">{{content.sub_title}}</span>
                <br>
                <div [innerHTML]="content.description | safeHtml"></div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>

    <div class="column is-6" style="margin-right:17%; width:33%">
      <div *ngFor="let content of contents" [id]="content.content_uid">
        <div class="box" *ngIf="content.order%2 ===1" style="margin-bottom:10px; height:22rem">
          <article class="media">
            <div class="media-left" *ngIf="content.images.length">
              <figure class="image is-128x128">
                <!--img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
                <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <strong style="font-size:24px" [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                <span class="ml-1">{{content.sub_title}}</span>
                <br>
                <div [innerHTML]="content.description | safeHtml"></div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>


  </div>





</section>
