import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { SITE_LICENSED_SOFTWARE, SITE_LICENSED_SOFTWARE_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-licensed-software',
  templateUrl: './site-licensed-software.component.html',
  styleUrls: ['./site-licensed-software.component.css']
})
export class SiteLicensedSoftwareComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = SITE_LICENSED_SOFTWARE;
    this.titleService.setTitle(SITE_LICENSED_SOFTWARE_TITLE);

  }

  ngOnInit() {
    this.getPage();
    this.getContent('datetime:DESC');
  }
}
