import { Routes } from '@angular/router';
import { AvailableComputingSystemComponent } from './available-computing-system/available-computing-system.component';
import {
  NewsEventsComponent,
  ServicesComponent,
  TeamComponent,
  AboutUsComponent,
  ContactUsComponent,
  LegalDisclaimerComponent,
  SitemapComponent,
  SearchComponent,
  LocationComponent,
  StoryComponent,
  NewsComponent,
  PruebaComponent,
  ResearchComputingComponent,
  HighPerformanceComputingComponent,
  SiteLicensedSoftwareComponent,
  OnboardingComponent,
  OnboardingHpcComponent,
  OnboardingRcComponent,
  EventsComponent
} from './component.index';

import { HomeComponent } from './home/home.component';

export const ComponentRoutes: Routes = [

  { path: 'home', component: HomeComponent },
  { path: 'available-computing-system', component: AvailableComputingSystemComponent },
  {
    path: 'news-events',
    component: NewsComponent
  },
  {
    path: 'events',
    component: EventsComponent
  },
  {
    path: 'service/:firstlevel/:secondlevel/:thirdlevel',
    component: ServicesComponent
  },
  {
    path: 'service/:firstlevel/:secondlevel',
    component: ServicesComponent
  },
  {
    path: 'service/:firstlevel',
    component: ServicesComponent
  },
  {
    path: 'service',
    component: ServicesComponent
  },

  {
    path: 'onboarding/:firstlevel/:secondlevel/:thirdlevel',
    component: OnboardingComponent
  },
  {
    path: 'onboarding/:firstlevel/:secondlevel',
    component: OnboardingComponent
  },
  {
    path: 'onboarding/:firstlevel',
    component: OnboardingComponent
  },
  {
    path: 'onboarding',
    component: OnboardingComponent
  },

  {
    path: 'onboarding-hpc/:firstlevel/:secondlevel/:thirdlevel',
    component: OnboardingHpcComponent
  },
  {
    path: 'onboarding-hpc/:firstlevel/:secondlevel',
    component: OnboardingHpcComponent
  },
  {
    path: 'onboarding-hpc/:firstlevel',
    component: OnboardingHpcComponent
  },
  {
    path: 'onboarding-hpc',
    component: OnboardingHpcComponent
  },


  {
    path: 'onboarding-rc/:firstlevel/:secondlevel/:thirdlevel',
    component: OnboardingRcComponent
  },
  {
    path: 'onboarding-rc/:firstlevel/:secondlevel',
    component: OnboardingRcComponent
  },
  {
    path: 'onboarding-rc/:firstlevel',
    component: OnboardingRcComponent
  },
  {
    path: 'onboarding-rc',
    component: OnboardingRcComponent
  },


  {
    path: 'team',
    component: TeamComponent
  },
  { path: 'about/:firstlevel/:secondlevel/:thirdlevel',    component: AboutUsComponent  },
  { path: 'about/:firstlevel/:secondlevel',    component: AboutUsComponent  },
  { path: 'about/:firstlevel',    component: AboutUsComponent  },
    { path: 'about', component: AboutUsComponent },
  /*{ path: 'about', component: PruebaComponent },*/

  { path: 'research-computing', component: ResearchComputingComponent },
  { path: 'high-performance-computing', component: HighPerformanceComputingComponent },

  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'legal-disclaimer',
    component: LegalDisclaimerComponent
  },
  {
    path: 'sitemap',
    component: SitemapComponent
  },
  {
    path: 'search/:searchText',
    component: SearchComponent
  },

  { path: 'location/:firstlevel/:secondlevel/:thirdlevel', component: LocationComponent },
  { path: 'location/:firstlevel/:secondlevel', component: LocationComponent },
  { path: 'location/:firstlevel', component: LocationComponent },
  { path: 'location', component: LocationComponent },

  { path: 'story', component: StoryComponent },
  { path: 'news', component: NewsComponent },

  { path: 'site-licensed-software/:firstlevel/:secondlevel/:thirdlevel', component: SiteLicensedSoftwareComponent },
  { path: 'site-licensed-software/:firstlevel/:secondlevel', component: SiteLicensedSoftwareComponent },
  { path: 'site-licensed-software/:firstlevel', component: SiteLicensedSoftwareComponent },
  { path: 'site-licensed-software', component: SiteLicensedSoftwareComponent },


  { path: 'prueba/:firstlevel/:secondlevel/:thirdlevel', component: PruebaComponent  },
  { path: 'prueba/:firstlevel/:secondlevel',   component: PruebaComponent  },
  { path: 'prueba/:firstlevel',   component: PruebaComponent  },
  { path: 'prueba', component: PruebaComponent }
];
