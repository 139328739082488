import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { INav, IPage,  ISliderData, ISliderObject, IImage } from '../../shared/model/model.index';

import { NEWS, STORY, EVENTS, LOCATION, HOME } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  
  _sharedService: SharedService;
  news: IPage;
  events: IPage;
  story: IPage;
  contentsEvents: any;
  contentsHome: any;
  baseUrl: string = environment.cmsApiBaseUrl;

  constructor(sharedService: SharedService,
    //public sanitizer: DomSanitizer,
    private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = HOME;
    this._sharedService = sharedService;
    this.titleService.setTitle("RTS");
    this.getSliders();
    this.getData();
  }

  ngOnInit() {
    this.getContent('datetime:DESC');
  }



  getData(): void {

    this._sharedService.getPageByUID(STORY).subscribe(p => {
      if (p) {

        const attr = p.data[0].attributes;
        const navP: INav = { created_at: "", id: 1, is_disabled: false, is_external: true, is_parent: false, name: "mission", published_at: "", sub_navs: null, updated_at: "", url: "mission" }

        const thisPage: IPage = {
          id: p.data[0].id, content_display_type: null,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url: attr.url
        }

        this.story = thisPage;

      }

    });


    this.getHomeContent();

  }


  getHomeContent(sort: string = null) {
    this._sharedService.getPageContentByID(HOME, sort).subscribe(c => {
      this.contentsHome = c;
      if (this.contentsHome.length > 0) {
        this.selectedTitle = this.contentsEvents[0].title;
        if (this.firstLevel) {
          this.selectedTitle = this.firstLevel;
        }
        if (this.contentsHome[0].subcontents.length > 0) {
          this.selectedSubTitle = this.contentsHome[0].subcontents[0].title;
          if (this.secondLevel) {
            this.selectedSubTitle = this.secondLevel;
          }
        }
      }
    });
  }

 
}
