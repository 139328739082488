<!-- <div style="height:5em">
  <h3 class="title">
    SOFTWARE
  </h3>
</div>
<div style="height:20em; ">
  
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="max-width:32em;  padding-top:7em; margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/rc-banner-sharp.png" />
  </div>
</div> -->

<div style="height:20px; "></div>
<div style="align-content:center; margin-right:20em">
  <iframe width="560" height="315 " src="https://www.youtube.com/embed/9R7QoNfmm0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>

<section class="content section">
  <div class="container" *ngIf="page">
    <!--<h3 class="title">
      {{page.title}}
    </h3>-->

    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>


    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <div class="box" *ngFor="let content of contents" [id]="content.content_uid">
        <article class="media">
          <div class="media-left" *ngIf="content.images.length">
            <figure class="image is-128x128">
              <!--img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
              <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title">
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
              <span class="ml-1">{{content.sub_title}}</span>
              <br>
              <div class="rc-content" [innerHTML]="content.description | safeHtml"></div>
            </div>
          </div>
        </article>
      </div>
    </div>

  </div>
</section>

