<div style="height:5em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 5em; width: 100%; z-index:1; position:absolute;" />
  <!--<div style="min-width:13em; max-width:17em; width:20%; padding-top:7.5em; margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/contact-banner-sharp.png" />
  </div>-->
</div>

<section class="content">
  <section class="section" *ngIf="page">
    <div class="container">
      <h3 class="title">
        {{page.title}}
      </h3>
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
        </div>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
        <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
        <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
        <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
      </div>

    </div>
  </section>
</section>

<!--<ng-template #contentTemplate let-content="content">
  <div class="columns">
    <div class="column is-5">
      <div class="box" style="height:100%">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(content.url)" frameborder="0" style="border:0; width:100%" allowfullscreen=""></iframe>
      </div>
    </div>
    <div class="column">
      <div class="box" style="height:100%">
        <p class="title is-5"><span [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</span></p>
        <div [innerHTML]="content.description | safeHtml"></div>
      </div>
    </div>
  </div>
</ng-template>-->

<ng-template #contentTemplate let-content="content">
  <div class="tile is-ancestor">
    <div class="tile is-vertical is-12">
      <div class="tile is-parent">
        <article class="tile is-child">
          <div class="content">
            <div [innerHTML]="content.description | safeHtml">
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child">
        <div class="content">
          <div class="content">
            <figure class="image">
              <!--<img  *ngIf="content.images.length" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"/>-->
              <!--<img *ngIf="content.images.length" [src]="_cmsApiUrl + content.images[0].formats.thumbnail.url.replace('uploads','../uploads')" [alt]="content.title">-->
            </figure>
          </div>
        </div>
      </article>
    </div>
  </div>
</ng-template>

