import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {

  NewsEventsComponent,
  ServicesComponent,
  TeamComponent,
  AboutUsComponent,
  ContactUsComponent,
  LegalDisclaimerComponent,
  SitemapComponent,
  SearchComponent 
} from './component.index';

import { ComponentRoutes } from './component.routes';
import { LocationComponent } from './location/location.component';
import { NewsComponent } from './news/news.component';
import { StoryComponent } from './story/story.component';
import { PruebaComponent } from './prueba/prueba.component';
import { ResearchComputingComponent } from './research-computing/research-computing.component';
import { HighPerformanceComputingComponent } from './high-performance-computing/high-performance-computing.component';
import { AvailableComputingSystemComponent } from './available-computing-system/available-computing-system.component';
import { SiteLicensedSoftwareComponent } from './site-licensed-software/site-licensed-software.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingHpcComponent } from './onboarding-hpc/onboarding-hpc.component';
import { OnboardingRcComponent } from './onboarding-rc/onboarding-rc.component';
import { EventsComponent } from './events/events.component';

@NgModule({
  declarations: [

    NewsEventsComponent,
    ServicesComponent,
    TeamComponent,
    AboutUsComponent,
    ContactUsComponent,
    LegalDisclaimerComponent,
    SitemapComponent,
    SearchComponent,
    LocationComponent,
    NewsComponent,
    StoryComponent,
    PruebaComponent,
    ResearchComputingComponent,
    HighPerformanceComputingComponent,
    AvailableComputingSystemComponent,
    SiteLicensedSoftwareComponent,
    OnboardingComponent,
    OnboardingHpcComponent,
    OnboardingRcComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    RouterModule.forChild(ComponentRoutes),
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule
  ]
})
export class ComponentModule { }
