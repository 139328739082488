import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { EVENTS, EVENTS_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'news-events',
  templateUrl: './news-events.component.html',
  styleUrls: ['./news-events.component.css']
})
export class NewsEventsComponent extends BaseComponent implements OnInit {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = EVENTS;
    this.titleService.setTitle(EVENTS_TITLE);
  }

  ngOnInit() {
    this.getPage();
    this.getContent('datetime:DESC'); 
  }
}
