<!--https://gsr.dev/material2-carousel/-->
<!--https://github.com/gbrlsnchs/material2-carousel-->

<div class="wrapper">
  <div class="columns mb-0">
    <div class="column is-full p-0">
      <mat-carousel timings="250ms ease-in"
                    [autoplay]="true"
                    interval="6000"
                    color="accent"
                    maxWidth="auto"
                    slides="5"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="true"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    [maintainAspectRatio]="true"
                    [proportion]="29"
                    orientation="ltr">
        <mat-carousel-slide #matCarouselSlide
                            *ngFor="let slide of slidersData; let i = index"
                            overlayColor="#00000040"
                            [hideOverlay]="true">
          <a [attr.href]="slide.attributes.url" target="_blank" *ngIf="slide.attributes.is_external && slide.attributes.url">
            <img [src]="slide.attributes.image.data.attributes.url">
          </a>
          <a [routerLink]="slide.attributes.url" *ngIf="!slide.attributes.is_external  && slide.attributes.image.data.attributes.url">
            <img [src]="slide.attributes.image.data.attributes.url">
          </a>
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>

  <br />

  <div class="columns m-2" style="padding-left:60px; padding-right:60px;">
    <div class="column is-6 ">


      <div class="box" *ngIf="story" style="max-height:108%; height:111%">
        <br />
        <h1 class="title">
          {{story.title}}
        </h1>

        <div class="m-b-sm m-t-sm" style="width:75%;margin:auto;">
        <div [innerHTML]="story.description | safeHtml"></div>
        </div>
      </div>
    </div>

    <div class="column is-6 ">

      <div class="box" style="max-height:53%; height:54%; margin-bottom:10px; padding:40px;">
        <div class="title" *ngIf="contents && contents[0] && contents[0].title">{{contents[0].title}}</div>
        <div style=" display: inline-block; vertical-align: top; ">
          <figure class="image"  *ngIf="contents && contents[0] && contents[0].images">
            <img src="{{contents[0].images[0].url}}" style="height:200px; max-height:200px; margin-top:10px; border-radius:5px" />
          </figure>
        </div>

        <div style=" display: inline-block; vertical-align: top; width: 49%; padding-left:30px">


          <div *ngIf="contents && contents[0] && contents[0].description" [innerHTML]="contents[0].description | safeHtml"></div>
        </div>
      </div>


      <div class="box" style="max-height: 54%; height: 55%; margin-top: 15px; padding: 40px;">

        <div class="title" *ngIf="contents && contents[1] && contents[1].title">{{contents[1].title}}</div>
        <div style="display: inline-block; vertical-align: top; ">
          <figure *ngIf="contents && contents[1]">
            <img src="{{contents[1].images[0].url}}" style="height:200px; max-height:200px; margin-top:10px; border-radius:5px" />
          </figure>
        </div>

        <div style=" display: inline-block; vertical-align: top; width: 50%; padding-left:30px">
        <div *ngIf="contents  && contents[1] && contents[1].description" [innerHTML]="contents[1].description | safeHtml"></div>
        </div>
      </div>

    </div>
  </div>

  <br /><br />
  <hr style="margin-top:3rem; color:whitesmoke" />
  <br />

</div>

