<div>
  <div *ngFor="let content of contents">
    <div *ngIf="!loadContentTemplate" class="box">
      <!--<ng-container [ngTemplateOutlet]="contentTemplate"
                    [ngTemplateOutletContext]="{content:content}"></ng-container>-->


      <div class="m-b-sm m-t-sm" *ngIf="contents.length > 0  ">
        
          <article class="media">
            <div class="media-left" *ngIf="content.images.length">
              <figure class="image is-128x128">
                <!-- <img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
                <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                <span class="ml-1">{{content.sub_title}}</span>
                <br>
                <div [innerHTML]="content.description | safeHtml"></div>
              </div>
            </div>
          </article>
        
      </div>


    </div>
    <div *ngIf="loadContentTemplate" class="box">
      <div [innerHTML]="content.title | safeHtml" style="font-weight:bolder; background-color:lightgrey; padding: 15px"></div><br />
      <div *ngIf="content.notification" [innerHTML]="content.notification | safeHtml"> </div>
      <div [innerHTML]="content.description | safeHtml"> </div>
    </div>
    <p></p>
  </div>
</div>
