export const HOME = 1;
export const ABOUT_US = 2;
export const CONTACT_US = 3;
export const NEWS = 4;
export const EVENTS = 5;

export const SERVICE =  6;
export const STORY = 7;
export const LOCATION = 8;
export const RESEARCH_COMPUTING =  9;
export const HIGH_PERFORMANCE_COMPUTING = 10;
export const AVAILABLE_COMPUTING_SYSTEM = 14;


export const MISSION = 25; 

export const SAMPLE_PREPARATION = 12;

export const TEAM = 15;
export const SITE_LICENSED_SOFTWARE = 19

export const LEGAL_DISCLAIMER = 21;


export const SITEMAP = 24;
export const ACKNOWLEDGING_THE_FACILITY = 29;
export const ONBOARDING = 40;
export const ONBOARDING_HPC = 41;
export const ONBOARDING_RC = 42;

export const PRUEBA = 99;

export const EVENTS_TITLE = "Upcoming Events";
export const LOCATION_TITLE = "Lab Locations";
export const STORY_TITLE = "Story";
export const NEWS_TITLE = "NEWS";
export const PRUEBA_TITLE = "TEST";
export const RESEARCH_COMPUTING_TITLE = "Research Computing";
export const HIGH_PERFORMANCE_COMPUTING_TITLE = "High Performance Computing";
export const AVAILABLE_COMPUTING_SYSTEM_TITLE = "Available Computing System"

export const BIOSAFETY_TITLE = "Biosafety";
export const CANCER_CENTER_SUPPORT_TITLE = "Cancer Center Support Grant";
export const EDUCATION_TITLE = "Education";
export const FOR_OPERATORS_TITLE = "For Operators";
export const GOOD_FLOW_NOTES_TITLE = "Good Flow Notes";
export const GUIDELINES_TITLE = "Instruments Guidelines";
export const INSTRUMENTS_TITLE = "Instruments";

export const NEWS_EVENTS_TITLE = "Events/News";
export const NEW_USER_TITLE = "New User";
export const PANEL_DESIGN_TITLE = "Panel Design";
export const SAMPLE_PREPARATION_TITLE = "Sample Preparation";
export const SCIENCE_TITLE = "Science";
export const SERVICE_TITLE = "Service";
export const TEAM_TITLE = "Team";
export const TECHNOLOGY_TITLE = "Technology";
export const TRAINING_TITLE = "Training";
export const TROUBLESHOOTING_TITLE = "Troubleshooting";
export const ABOUT_US_TITLE = "About us";
export const CONTACT_US_TITLE = "Contact us";
export const LEGAL_DISCLAIMER_TITLE = "Legal Disclaimer";
export const PRIVACY_POLICY_TITLE = "Privacy Policy";
export const PUBLIC_NOTICES_TITLE = "Public Noticies";
export const SITEMAP_TITLE = "Sitemap";
export const FLOW_POST_ITS_TITLE = "Flow Post-its";
export const PROTOCOLS_TITLE = "Protocols";
export const SORT_CALCULATOR_TITLE = "Sort Calculator";
export const FACILITY_POLICY_TITLE = "Facility Policy";
export const ONLINE_TOOLS_TITLE = "Online Tools";
export const SITE_LICENSED_SOFTWARE_TITLE = "Site Licensed Software";
export const ONBOARDING_TITLE = "Onboarding";
