import { Component, OnInit } from '@angular/core';
import {
  IconDefinition,
  faTwitterSquare,
  faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';

import SharedService from '../../shared/shared.service';

import { DataEntity, INav, INavObject } from '../../shared/model/model.index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isExpanded: boolean;
  isSearching: boolean;
  isSearchTextFocus: boolean;
  searchText: string;
  
  navMenuData: DataEntity[] =[];

  faTwitter: IconDefinition = faTwitterSquare;
  faYoutube: IconDefinition = faYoutubeSquare;

  constructor(private sharedService: SharedService,
    private router: Router) {

  }

  ngOnInit() {
    this.getNavMenuItems();
  }

  getNavMenuItems(): void {
    this.sharedService.getNavMenu().subscribe((response: INavObject) => {

      this.navMenuData= response.data;

    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  onSearch() {
    this.isSearching = !this.isSearching;
    this.router.navigate(['/search', this.searchText.trim()]);
    this.isSearching = !this.isSearching;
  }

  searchTextFocus() {
    this.isSearchTextFocus = !this.isSearchTextFocus;
  }
}
