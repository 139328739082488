<div style="height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="min-width:13em; max-width:17em; width:20%; padding-top:7em; margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/about-banner-sharp.png" />
  </div>
</div>

<section class="content section">
  <div class="container" *ngIf="page">

    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>


    <div class="m-b-sm m-t-sm about-notification" *ngIf="page.notification">
      <div class="editor ql-editor " [innerHTML]="page.notification | safeHtml">
      </div>
    </div>



    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="false"></tabs>
    </div>


  </div>
</section>



<ng-template #contentTemplate let-content="content">
  <div class="m-b-sm m-t-sm columns m-2" *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST" style="margin:auto; width:30%">
  </div>
</ng-template>


