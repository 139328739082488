<div style="height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="max-width:90em;   margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/service.png" />
  </div>
</div>

<section class="content section">
  <div class="container" *ngIf="page">

    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents"></accordion>
    </div>

    <hr/>
<!--
    <div class="m-b-sm m-t-sm columns m-2" *ngIf="contents.length > 0 ">

      <div class="column is-4" style="margin-left:0%; width:33%">
        <div *ngFor="let content of contents" [id]="content.content_uid">
          <div class="box" *ngIf="content.order%2 ===0" style="margin-bottom: 10px; height: 22rem; overflow: auto;">
            <article class="media">

              <div class="media-content">
                <div class="content">
                  <strong style="font-size:20px" [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                  <br />
                  <span class="ml-1">{{content.sub_title}}</span>
                  <br>
                  <div [innerHTML]="content.description | safeHtml"></div>
                </div>
   
              </div>
            </article>
          </div>
        </div>
      </div>

      <div class="column is-4" style="width:33%">
        <div *ngFor="let content of contents" [id]="content.content_uid">
          <div class="box" *ngIf="content.order%3 ===0" style="margin-bottom: 10px; height: 22rem; overflow: auto;">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <strong style="font-size:20px" [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                  <br />
                  <span class="ml-1">{{content.sub_title}}</span>
                  <br>
                  <div [innerHTML]="content.description | safeHtml"></div>
                </div>
           </div>
            </article>
          </div>
        </div>
      </div>


      <div class="column is-4" style="margin-right: 17%; width: 33%; ">
        <div *ngFor="let content of contents" [id]="content.content_uid">
          <div class="box" *ngIf="content.order%5 ===0" style="margin-bottom: 10px; height: 22rem; overflow: auto;">
            <article class="media">
              <div class="media-left" *ngIf="content.images.length && content.images.length>0 && content.images[0].url">
                <figure class="image is-128x128">
                  <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title" style="height:100px; width:100px">
                </figure>
              </div>
              <div class="media-content">
                <div class="content">
                  <strong style="font-size:20px" [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                  <br />
                  <span class="ml-1">{{content.sub_title}}</span>
                  <br>
                  <div [innerHTML]="content.description | safeHtml"></div>
                </div>

              </div>
         
            </article>
          </div>
        </div>
      </div>

    </div>

  -->



  </div>
</section>

