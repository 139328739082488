<div style="height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="min-width:13em; max-width:17em; width:20%; padding-top:7.5em; margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/contact-banner-sharp.png" />
  </div>
</div>


<!--<div style="max-height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="width: 77em; margin: auto;  z-index: 2; position: relative; ">
    <img src="../../../assets/images/contact-banner.png" />
  </div>
</div>-->

<div style="height:1rem"></div>

<div class="container m-b-sm m-t-sm">


  <p style="text-align:center;">
    <!--<a href="mailto:rts@mskcc.org?Subject=RTS%20Question&amp;body=" target="_blank" ><span class="w3-btn w3-button button mat-raised-button" style="color:white; background-color:navy">Email: rts@mskcc.org</span></a></p><h4 style="text-align:center;">-->
    <a href="mailto:rts@mskcc.org?Subject=RTS%20Question&amp;body=" target="_blank" class="w3-btn  button mat-raised-button" style="border-radius: 6px!important; color: white; background-color: navy;">Email: rts@mskcc.org</a>
  </p><h4 style="text-align:center;">

    <br />
    <!--<a href="tel:212-639-2000" style="border-radius: 6px; background-color:yellow!important"><span class="w3-btn w3-button button mat-raised-button" style="color:white; background-color:navy;">Phone: 212-639-2000</span></a>-->

    <a href="tel:212-639-2000" class="w3-btn  button mat-raised-button" style="border-radius: 6px!important; color: white; background-color: navy;">Phone: 212-639-2000</a>
  </h4>



</div>



<section class="content section">
  <div class="container" *ngIf="page">

    <div class="m-b-sm m-t-sm " *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <!--
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>-->
    <!--<div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
    <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
  </div>

  <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
    <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
  </div>

  <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
    <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
  </div>-->
    <!--<div class="m-b-sm m-t-sm" *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
    <div class="" *ngFor="let content of contents" [id]="content.content_uid">
      <article class="media">
        <div class="media-left" *ngIf="content.images.length">
          <figure class="image is-128x128">
            <img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title">
          </figure>
        </div>
        <div class="media-content" >
          <div class="content ">
            <div style="background-color: navy; color: white!important; width:15em" [innerHTML]="content.description | safeHtml"></div>
          </div>
        </div>
      </article>
    </div>
  </div>-->





  </div>
</section>

