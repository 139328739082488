import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { EVENTS, EVENTS_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
  
})

export class EventsComponent extends BaseComponent implements OnInit {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router
    //,     mat:MatCarousel
    ) {
    super(sharedService, route, router);
    this.uid = EVENTS;
    this.titleService.setTitle(EVENTS_TITLE);
    this.getSliders();
  }

}