<footer class="footer p-4">
  <div class="container">
    <div class="content is-size-7">
      <div class="columns">
        <div class="column has-text-left">
          <p>
            Connect with us:
            <fa-icon [icon]="faPhone" class="mr-1"></fa-icon>800-639-2000
            <a [routerLink]="['/location']" class="ml-2">
              <fa-icon [icon]="faMapMarker"></fa-icon>
              Locations
            </a>
          </p>
          <br/>
          <p>
            <a [routerLink]="['/about']">
              About us
            </a> 
            <!--<a [routerLink]="['/news']">
    News & Information
  </a> |
  <a [routerLink]="['/events']">
    Events
  </a> |
  <a [routerLink]="['/contact-us']">
    Contact us
  </a>-->
          </p>
        </div>

        <div class="column has-text-centered p-0">
          <img src="../../assets/images/MSKCC_logo_hor_s_rev_rgb_150.png" class="mt-3" style="height:80px">

        </div>

        <div class="column has-text-right">
          <p>
            © 2022 Memorial Sloan Kettering Cancer Center
          </p>
          <br />
          <p class="mb-0">
            <a [routerLink]="['/events']">
              Events
            </a> |
            <a [routerLink]="['/contact-us']">
              Contact Us
            </a> 
          </p>
          
        </div>
      </div>
    </div>
  </div>
</footer>
