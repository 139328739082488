<div style="height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="min-width:13em; max-width:32em; padding-top:7em; margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/news-banner-sharp.png" />
  </div>
</div>


<section class="content section">

  <div class="container">

    <div class="m-b-sm m-t-sm" *ngIf="page && page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div> 

     <div class="m-b-sm m-t-sm" *ngIf="page && page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>


    <div class="m-b-sm m-t-sm" *ngIf="page &&  page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
    </div>

    <!--LIST: EATHER [loadContentTemplate]="true" or below-->

  </div>

<div class="columns mb-0" style="width: 75%; margin: auto;" >
    <div class="column is-full p-0">
      <mat-carousel timings="250ms ease-in"
                    [autoplay]="true"
                    interval="6000"
                    color="accent"
                    maxWidth="auto"
                    slides="5"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="true"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    [maintainAspectRatio]="true"
                    [proportion]="29"
                    orientation="ltr">
        <mat-carousel-slide #matCarouselSlide
                            *ngFor="let slide of slidersDataNews; let i = index"
                            overlayColor="#00000040"
                            [hideOverlay]="true">
                            <a [attr.href]="slide.attributes.url" target="_blank" *ngIf="slide.attributes.is_external && slide.attributes.url">
                              <img [src]="slide.attributes.image.data.attributes.url">
                            </a>
                            <a [routerLink]="slide.attributes.url" *ngIf="!slide.attributes.is_external && slide.attributes.image.data.attributes.url">
                              <img [src]="slide.attributes.image.data.attributes.url">
                            </a>
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>
  <br/><br/>

</section>



