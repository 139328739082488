import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { SAMPLE_PREPARATION, SAMPLE_PREPARATION_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: []
})
export class SearchComponent extends BaseComponent implements OnInit {
  private searchText: string;
  private _sharedService: SharedService;
  private _route: ActivatedRoute;

  constructor(sharedService: SharedService,
    private titleService: Title,
    route: ActivatedRoute,
    router: Router) {
    super(sharedService, route, router);
    this.uid = SAMPLE_PREPARATION;
    this._sharedService = sharedService;
    this._route = route;
    this.titleService.setTitle(SAMPLE_PREPARATION_TITLE);
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.searchText = params['searchText'];
      this._sharedService.getSearchContent(this.searchText.trim()).subscribe(r => {
      });
    });
  }
}
