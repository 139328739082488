import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { ONBOARDING_RC } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-rc',
  templateUrl: './onboarding-rc.component.html',
  styleUrls: ['./onboarding-rc.component.css']
})
export class OnboardingRcComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = ONBOARDING_RC;
    this.titleService.setTitle("Onboarding Research Computing");

  }

}
